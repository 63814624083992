<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tpm.course_name') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="course-managment" :class="'btn btn-success text-light'">{{ $t('elearning_tpm.course_name') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-overlay :show="loading">
                        <b-row>
                          <b-col v-if="$store.state.Auth.activeRoleId === 1" lg="6" sm="6">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select> -->
                                <v-select
                                      id="org_id"
                                      v-model="formData.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_type_id"
                                      v-model="formData.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_category_id"
                                      v-model="formData.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="training_title_id"
                                      v-model="formData.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Lesson Module"  vid="course_module_id">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="course_module_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_tpm.course_module')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                  plain
                                  v-model="formData.course_module_id"
                                  :options="courseModuleList"
                                  id="course_module_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="course_module_id"
                                      v-model="formData.course_module_id"
                                      :reduce="op => op.value"
                                      :options="courseModuleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                                <small v-if="courseError" class="text-danger">
                                  {{courseError}}
                                </small>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-overlay :show="loadingCourseModule">
                          <b-row>
                            <div class="col-md-12">
                              <fieldset class="p-2 w-100">
                                <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tpm.course_name') }} {{ $t('globalTrans.entry') }}</legend>
                                <table class="table" style="width:100%">
                                  <thead class="thead">
                                    <tr>
                                      <th style="width:17%">{{$t('elearning_tpm.course_name_en')}} <span class="text-danger">*</span></th>
                                      <th style="width:17%">{{$t('elearning_tpm.course_name_bn')}} <span class="text-danger">*</span></th>
                                      <th style="width:20%">{{$t('elearning_tpm.course_details_en')}}</th>
                                      <th style="width:20%">{{$t('elearning_tpm.course_details_bn')}}</th>
                                      <th style="width:10%"></th>
                                    </tr>
                                  </thead>
                                  <tr v-for="(lesson,index) in formData.lessons" :key="index">
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_name' + index" name="Course name (En)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-input
                                              rows="2"
                                              v-model="lesson.course_name"
                                              :name="'lessons['+index+'][course_name]'"
                                              :id="'lessons['+index+'][course_name]'"
                                              :placeholder="$t('elearning_tpm.course_name_en')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_name']" class="text-danger">
                                              {{ course_module_errors['lessons.'+index+'.course_name'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_name_bn' + index"  name="Course name (Bn)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-input
                                              rows="2"
                                              v-model="lesson.course_name_bn"
                                              :name="'lessons['+index+'][course_name_bn]'"
                                              :id="'lessons['+index+'][course_name_bn]'"
                                              :placeholder="$t('elearning_tpm.course_name_bn')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_name_bn']" class="text-danger">
                                              {{ course_module_errors['lessons.'+index+'.course_name_bn'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_details' + index" name="Course Details (En)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-textarea
                                              rows="2"
                                              v-model="lesson.course_details"
                                              :name="'lessons['+index+'][course_details]'"
                                              :id="'lessons['+index+'][course_details]'"
                                              :placeholder="$t('elearning_tpm.course_details_en')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.course_details']" class="text-danger">
                                              {{ course_module_errors['lessons.'+index+'.course_details'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <keep-alive>
                                        <ValidationProvider :vid="'course_details_bn' + index" name="Course Details (Bn)">
                                          <div slot-scope="{ valid, errors }">
                                            <b-form-textarea
                                              rows="2"
                                              v-model="lesson.course_details_bn"
                                              :name="'lessons['+index+'][course_details_bn]'"
                                              :id="'lessons['+index+'][course_details_bn]'"
                                              :placeholder="$t('elearning_tpm.course_details_bn')"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="error invalid-feedback" role="alert">
                                              {{ errors[0] }}
                                            </div>
                                            <small v-if="course_module_errors && course_module_errors['lessons.' + index + '.name']" class="text-danger">
                                              {{ course_module_errors['lessons.'+index+'.name'] }}
                                            </small>
                                          </div>
                                        </ValidationProvider>
                                      </keep-alive>
                                    </td>
                                    <td>
                                      <button
                                          v-show="index == formData.lessons.length-1"
                                          @click="addMore"
                                          class="btn btn-sm btn-primary mr-1 pt-0 pb-0 pl-1 pr-1"
                                          type="button"
                                        >{{$t('globalTrans.add_more')}}
                                      </button>
                                      <!-- <button
                                          v-show="index == formData.lessons.length-1"
                                          @click="addMore"
                                          class="btn btn-sm btn-primary mr-1"
                                          type="button"
                                        ><i class="fa fa-plus" aria-hidden="true"></i>
                                      </button> -->
                                      <button @click="removeCourseModule(index, lesson)" class="btn btn-sm btn-danger" type="button">
                                        <i class="fas fa-window-close m-0"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <!-- <tr>
                                    <td colspan="5">
                                      <button
                                        @click="addMore"
                                        class="btn btn-sm btn-primary float-right"
                                        type="button"
                                      >{{$t('globalTrans.add_more')}}</button>
                                    </td>
                                  </tr> -->
                                  <template v-if="formData.lessons.length === 0">
                                    <tr>
                                      <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                    </tr>
                                  </template>
                                  <tr>
                                    <td colspan="5">
                                      <button
                                        @click="addAll"
                                        class="btn btn-sm btn-primary float-right"
                                        type="button"
                                      >{{$t('elearning_tpm.add_in_list')}}</button>
                                    </td>
                                  </tr>
                                </table>
                              </fieldset>
                            </div>
                          </b-row>
                        </b-overlay>
                        <!-- <b-row>
                          <div class="col-md-12">
                            <button
                              @click="addAll"
                              class="btn btn-sm btn-primary float-right"
                              type="button"
                            ><i class="fa fa-plus" aria-hidden="true"></i></button>
                          </div>
                        </b-row> -->
                        <b-row>
                          <div class="col-md-12">
                            <fieldset class="p-2 w-100">
                              <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.course_name')}} {{$t('globalTrans.list')}}</legend>
                              <table class="table" style="width:100%">
                                <thead class="thead">
                                  <tr>
                                    <th style="width:17%">{{$t('elearning_tpm.course_module')}}</th>
                                    <th style="width:17%">{{$t('elearning_tpm.course_name')}}</th>
                                    <th style="width:20%">{{$t('elearning_tpm.course_details')}} </th>
                                  </tr>
                                </thead>
                                <tr v-for="(course_name,index) in formData.course_management_list" :key="index">
                                  <td>{{ $i18n.locale === 'bn' ? course_name.module_name_bn : course_name.module_name }}</td>
                                  <td>{{ $i18n.locale === 'bn' ? course_name.course_name_bn : course_name.course_name }}</td>
                                  <td>{{ $i18n.locale === 'bn' ? course_name.course_details_bn : course_name.course_details }}</td>
                                </tr>
                                <template v-if="formData.course_management_list.length === 0">
                                  <tr>
                                    <th colspan="3" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                  </tr>
                                </template>
                              </table>
                            </fieldset>
                          </div>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <router-link to="course-managment" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                            </div>
                        </div>
                      </b-overlay>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseManagmentStore, courseManagmentUpdate, courseManagmentShow, courseWiseModule } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.course_module_id) {
      const trTitle = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(item => item.value === parseInt(this.$route.query.course_module_id))
      if (typeof trTitle !== 'undefined') {
        this.formData.org_id = trTitle.org_id
        this.formData.training_type_id = trTitle.training_type_id
        this.formData.training_title_id = trTitle.training_title_id
        this.formData.training_category_id = trTitle.training_category_id
        this.formData.course_module_id = parseInt(this.$route.query.course_module_id)
      }
      // this.getFormData()
    }
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      loadingCourseModule: false,
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        org_id: '',
        training_category_id: '',
        training_type_id: '',
        training_title_id: '',
        course_module_id: '',
        lessons: [
          {
            id: '',
            course_name: null,
            course_name_bn: null,
            course_details: null,
            course_details_bn: null
          }
        ],
        course_management_list: [],
        lessons_remove: []
      },
      officeTypeList: [],
      trainers: [],
      officeList: [],
      courseModuleList: [],
      trainerListData: [],
      trainingCategoryList: [],
      course_module_errors: [],
      circularList: [],
      circularErrorMsg: false,
      courseError: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      trainingTypeList: [],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
    // trainingTypeList: function () {
    //   return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    // }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.courseModuleList = this.getcourseModuleList(newValue)
    },
    'formData.course_module_id': function (newValue) {
      if (newValue) {
        this.courseWiseModule(newValue)
      }
    }
  },
  methods: {
    removeCourseModule (index, data) {
      this.formData.lessons.splice(index, 1)
      this.formData.lessons_remove.push(data)
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
      return type
    },
    addAll () {
      const courseModuels = this.formData.course_management_list.find(course => course.course_module_id === parseInt(this.formData.course_module_id))
      if (typeof courseModuels === 'undefined') {
        this.formData.lessons.map(item => {
          if (this.formData.course_module_id && item.course_name && item.course_name_bn) {
            const modules = {}
            const courseObj = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(doc => doc.value === parseInt(this.formData.course_module_id))
            if (typeof courseObj !== 'undefined') {
              modules.module_name = courseObj.text_en
              modules.module_name_bn = courseObj.text_bn
            } else {
              modules.module_name = ''
              modules.module_name_bn = ''
            }
            modules.course_name = item.course_name
            modules.course_name_bn = item.course_name_bn
            modules.course_details = item.course_details
            modules.course_details_bn = item.course_details_bn
            modules.course_module_id = this.formData.course_module_id
            modules.org_id = this.formData.org_id
            modules.training_category_id = this.formData.training_category_id
            modules.training_type_id = this.formData.training_type_id
            modules.training_title_id = this.formData.training_title_id
            modules.id = item.id
            this.formData.course_management_list.push(modules)
          }
        })
        this.courseModuleListReset()
        this.formData.course_module_id = 0
      } else {
        this.courseError = 'This Course is allready assigned'
        return false
      }
    },
    addMore () {
      this.formData.lessons.push({
        id: '',
        course_name: null,
        course_name_bn: null,
        course_details: null,
        course_details_bn: null
      })
    },
    async courseWiseModule () {
      this.loadingCourseModule = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseWiseModule + '/' + this.formData.course_module_id)
      if (!result.success) {
        this.courseModuleListReset()
      } else {
        if (result.data.length > 0) {
          this.formData.lessons = result.data
        } else {
          this.courseModuleListReset()
        }
        this.formData.lessons_remove = []
      }
      this.loadingCourseModule = false
    },
    courseModuleListReset () {
      this.formData.lessons = [
        {
          id: '',
          course_name: null,
          course_name_bn: null,
          course_details: null,
          course_details_bn: null
        }
      ]
      this.formData.lessons_remove = []
    },
    async getFormData () {
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseManagmentShow + '/' + this.$route.query.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        this.formData.lessons = result.data.lessons
        this.formData.lessons_remove = []
      }
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    getcourseModuleList (trainingTitleId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
      return courseModuleList
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${courseManagmentUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseManagmentStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/course-managment')
      } else {
        this.course_module_errors = result.errors
        this.$refs.form.setErrors(result.errors)
        // this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
